<template>
  <div class="checkout-payment-method">
    <GooCreditCardLogo :type="paymentMethod.brand" />
    <div class="checkout-payment-method-details">
      <p>Card ending in {{ paymentMethod.lastDigits }}</p>
      <p>Expires {{ paymentMethod.expMonth }}/{{ paymentMethod.expYear }}</p>
    </div>
  </div>
</template>

<script>
  import GooCreditCardLogo from "@/goo2/components/goo-credit-card-logo/GooCreditCardLogo";

  export default {
    name: "CheckoutPaymentMethod",
    components: { GooCreditCardLogo },
    props: {
      paymentMethod: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss">
  @import "src/css/theme";

  .checkout-payment-method {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0 0 1rem;

    @extend .goo-box, .is-gray-light;

    &-details {
      margin: 0 0 0 1rem;

      @include respond-medium {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
      }

      p {
        margin: 0;
      }
    }
  }
</style>
