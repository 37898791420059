<template>
  <div class="checkout-promo-code-input">
    <div class="mb-2">
      <form v-if="expanded" class="checkout-promo-code-input-form" @submit.prevent="onSubmit">
        <input ref="field" v-model="code" autofocus :disabled="loading" class="goo-input is-small mr-4" placeholder="Promo code" aria-label="Promo Code">
        <goo-submit class="goo-button is-primary is-small is-outlined" :loading="loading" :loading-text="false">
          Apply
        </goo-submit>
      </form>
      <button v-else class="goo-link is-alternate" @click.prevent="onAddClick">
        +Add Promo Code
      </button>
    </div>
    <div v-if="error" class="is-error-text-color">
      {{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckoutPromoCodeInput",
    props: {
      expanded: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        code: ""
      };
    },
    methods: {
      onAddClick () {
        this.$emit("expand");

        this.$nextTick(() => {
          this.$refs.field.focus();
        });
      },
      onSubmit () {
        const code = this.code.trim();

        if (code === "") {
          this.$emit("input", null);
        } else {
          this.$emit("input", code.toUpperCase());
        }
      }
    }
  };
</script>

<style lang="scss">
  .checkout-promo-code-input {
    &-form {
      display: flex;
      button {
        width: 60px
      }
    }

    .goo-input {
      text-transform: uppercase;
    }

    ::placeholder {
      text-transform: initial;
    }
  }
</style>
