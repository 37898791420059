<template>
  <div class="checkout-layout">
    <div class="checkout-layout-sidebar">
      <div class="goo-panel is-paddingless">
        <slot name="sidebar" />
      </div>

      <slot name="accessory" />
    </div>

    <div class="checkout-layout-main">
      <div class="goo-panel">
        <slot name="main" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckoutLayout"
  };
</script>

<style lang="scss">
  @import "src/css/theme";

  .checkout-layout {
    padding: 0 1rem 1rem;
    max-width: 862px;
    margin: 0 auto;
    display: grid;
    grid-gap: 2rem;

    @include respond-medium {
      grid-gap: 1rem;
      grid-template-columns: 6fr 4fr;

      &-main { order: 1; }
      &-sidebar { order: 2; }
    }
  }
</style>
