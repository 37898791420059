<template>
  <div class="gooroo-pass-checkout-heading">
    <template v-if="heading && true">
      <h1 class="heading-2 is-marginless">
        {{ heading }}
      </h1>
      <p class="is-marginless">
        No commitment. Cancel anytime.
      </p>
    </template>
    <template v-else>
      <h1 class="heading-2 is-marginless">
        <GooSkeleton class="is-marginless" width="320" height="2rem" inline />
      </h1>
      <p class="is-marginless">
        <GooSkeleton width="280" height="1rem" inline />
      </p>
    </template>
  </div>
</template>

<script>
  import { GET_GOOROO_PASS_PRICING } from "@/components/GoorooPass/queries";

  export default {
    name: "GoorooPassCheckoutHeading",
    components: {},
    data () {
      return {
        pricing: null
      };
    },
    apollo: {
      pricing: GET_GOOROO_PASS_PRICING
    },
    computed: {
      heading () {
        if (this.pricing == null) {
          return null;
        }

        if (this.pricing.isEligibleForTrial) {
          return `Start your ${this.pricing.trialPeriodDays} day free trial`;
        }

        return "Get your Gooroo Pass today";
      }
    }
  };
</script>

<style lang="scss">
  .gooroo-pass-checkout-heading {
    text-align: center;
    padding: 2rem 0;
  }
</style>
