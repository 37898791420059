import gql from "graphql-tag";

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    result: updatePaymentMethod(input: $input) {
      ok
      error
      paymentMethod {
        id
        brand
        expMonth
        expYear
      }
    }
  }
`;

export const SUBSCRIBE_TO_GOOROO_PASS = gql`
  mutation SubscribeToGoorooPass($paymentMethod: String!, $token: String!) {
    subscribeToGoorooPass(paymentMethod: $paymentMethod, token: $token) {
      subscription {
        id
        status
      }
    }
  }
`;
