<template>
  <transition name="goo-modal-transition" appear>
    <goo-modal active>
      <div class="goo-dialog is-alert gooroo-pass-checkout-confirmation-dialog">
        <div class="has-text-centered">
          <header>
            <div class="goo-dialog-image has-text-centered">
              <goo-icon name="circle-check-outline" class="is-primary" width="60" height="60" />
            </div>

            <div class="goo-dialog-heading">
              <GooSkeleton v-if="loading" class="heading-3" width="100%" height="2.25rem" />
              <h3 v-else-if="subscription.onTrial" class="heading-3">
                Your free trial has started!
              </h3>
              <h3 v-else class="heading-3">
                You now have Gooroo Pass!
              </h3>
            </div>
          </header>

          <div class="goo-dialog-body">
            <GooBodyTextSkeleton v-if="loading" :lines="4" />
            <p v-else>
              You will be charged a recurring monthly fee of {{ pricing.price | formatCurrency }} at the end of your
              promotional period until you cancel. We will send you an email reminder 3 days before placing any
              charges to your account.
            </p>
          </div>

          <footer>
            <GooSkeleton v-if="loading" width="100%" height="45" />
            <a v-else :href="ctaURL" class="goo-button is-primary is-full-width">Continue</a>
          </footer>
        </div>
      </div>
    </goo-modal>
  </transition>
</template>

<script>
  import { formatDate, formatCurrency } from "@gooroo-life/goo-formatters";
  import { GET_GOOROO_PASS_CONFIRMATION_DATA } from "@/components/GoorooPass/queries";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";

  export default {
    name: "GoorooPassCheckoutConfirmationDialog",
    components: {},
    filters: {
      formatDate,
      formatCurrency
    },
    mixins: [SafeRedirectMixin],
    data () {
      return {
        nextBill: null,
        pricing: null,
        subscription: null
      };
    },
    apollo: {
      nextBill: GET_GOOROO_PASS_CONFIRMATION_DATA,
      pricing: GET_GOOROO_PASS_CONFIRMATION_DATA,
      subscription: GET_GOOROO_PASS_CONFIRMATION_DATA
    },
    computed: {
      loading () {
        return this.$apollo.queries.pricing.loading;
      },
      ctaURL () {
        return this.getSafeNextURL(process.env.VUE_APP_GOOROO_COURSES_URL);
      }
    }
  };
</script>

<style lang="scss">
  .gooroo-pass-checkout-confirmation-dialog {
    width: 100%;
  }
</style>
