<template>
  <table class="checkout-summary">
    <tbody class="checkout-summary-line-items">
      <tr v-for="item in quote.lineItems" :key="item.title">
        <td class="checkout-summary-title">
          {{ item.title }}
        </td>
        <td class="checkout-summary-amount">
          {{ item.amount | formatCurrency }}
        </td>
      </tr>
      <tr v-if="quote.discount" class="is-primary-text-color has-text-bold">
        <td class="checkout-summary-title">
          {{ quote.discount.title }}<br>
          <button class="goo-link is-alternate" @click="removePromoCode">
            Remove
          </button>
        </td>
        <td class="checkout-summary-amount">
          {{ quote.discount.amount | formatCurrency }}
        </td>
      </tr>
    </tbody>
    <tbody class="checkout-summary-total">
      <tr>
        <td class="checkout-summary-title">
          <span class="heading-4 is-marginless">Due today:</span>
        </td>
        <td class="checkout-summary-amount">
          <span class="heading-3 is-marginless">{{ quote.dueToday | formatCurrency }}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr v-if="quote.nextCharge">
        <td v-if="quote.discount" colspan="2">
          If you dont cancel your trial, you will be charged <strong>{{ quote.nextCharge.amount | formatCurrency }}</strong>
          per month (before discounts) at the end of your promotional period (if any).
        </td>
        <td v-else colspan="2">
          If you don’t cancel your trial, you will be charged <strong>{{ quote.nextCharge.amount | formatCurrency }}</strong>
          per month, starting on <strong>{{ quote.nextCharge.date | formatDate }}</strong>.
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
  import { formatDate, formatCurrency } from "@gooroo-life/goo-formatters";

  export default {
    name: "GoorooPassCheckoutSummary",
    filters: {
      formatCurrency,
      formatDate
    },
    props: {
      quote: {
        type: Object,
        required: true
      }
    },
    methods: {
      removePromoCode () {
        this.$emit("remove-promo-code");
      }
    }
  };
</script>

<style lang="scss">
  @import "src/css/theme";

  .checkout-summary {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    border-collapse: collapse;

    th {
      font-weight: bold;
      text-align: left;
    }

    td, th {
      vertical-align: middle;
    }

    &-line-items {
      td {
        padding: 0.5rem 1rem;
        vertical-align: top;

        @include respond-medium {
          padding: 0.5rem 1.5rem;
        }
      }

      tr:first-child td {
        padding-top: 1.5rem;
      }

      tr:last-child td {
        padding-bottom: 1.5rem;
      }
    }

    &-total {
      td {
        background: $gray-medium;
        padding: 0.5rem 1rem;

        @include respond-medium {
          padding: 0.5rem 1.5rem;
        }
      }
    }

    &-title {
      width: 100%;
      padding-right: 0.25rem!important;
    }

    &-amount {
      text-align: right;
      padding-left: 0.25rem!important;
    }

    tfoot td {
      padding: 1rem;

      @include respond-medium {
        padding: 1rem 1.5rem;
      }
    }
  }
</style>
