<template>
  <div>
    <Nav v-if="user" :product-name="product.name || 'PASS'" :user="user" :link="product.url" />

    <GoorooPassCheckoutHeading class="pt-11 pt-md-16" />

    <CheckoutLayout>
      <template slot="main">
        <GoorooPassPaymentForm :token="token" @complete="onComplete" />
      </template>

      <template slot="sidebar">
        <GoorooPassCheckoutSummary v-if="quote" :quote="quote" @remove-promo-code="removePromoCode" />
        <div v-else class="p-6">
          <GooBodyTextSkeleton :lines="4" class="is-marginless" />
        </div>
      </template>

      <template v-if="showPromoCodeInput" slot="accessory">
        <GoorooPassCheckoutPromoCodeInput @applied="onPromoCodeUpdate" />
      </template>
    </CheckoutLayout>

    <GoorooPassCheckoutConfirmationDialog v-if="showConfirmationDialog" />
  </div>
</template>

<script>
  import { PROMO_CODE_STORAGE_KEY } from "./constants";
  import { CREATE_GOOROO_PASS_QUOTE, GET_GOOROO_PASS_SUBSCRIPTION } from "./queries";

  import Nav from "@/components/Settings/components/Nav";
  import CheckoutLayout from "./components/CheckoutLayout";
  import GoorooPassPaymentForm from "./components/GoorooPassPaymentForm";
  import GoorooPassCheckoutHeading from "./components/GoorooPassCheckoutHeading";
  import GoorooPassCheckoutSummary from "./components/GoorooPassCheckoutSummary";
  import GoorooPassCheckoutConfirmationDialog from "./components/GoorooPassCheckoutConfirmationDialog";
  import GoorooPassCheckoutPromoCodeInput from "./components/GoorooPassCheckoutPromoCodeInput";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";

  export default {
    name: "GoorooPassCheckoutScreen",
    components: {
      Nav,
      CheckoutLayout,
      GoorooPassCheckoutSummary,
      GoorooPassPaymentForm,
      GoorooPassCheckoutHeading,
      GoorooPassCheckoutPromoCodeInput,
      GoorooPassCheckoutConfirmationDialog
    },
    mixins: [ProductAwareMixin, SafeRedirectMixin],
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        quote: null,
        showConfirmationDialog: false
      };
    },
    computed: {
      token () {
        if (this.quote == null) {
          return null;
        }

        return this.quote.token;
      },
      showPromoCodeInput () {
        return this.quote != null && this.quote.discount == null;
      }
    },
    mounted () {
      this.$apollo.query({
        query: GET_GOOROO_PASS_SUBSCRIPTION
      }).then(({ data: { subscription } }) => {
        if (subscription !== null && subscription.isActive) {
          this.redirectToNextURL();
        }
      }).finally(() => {
        this.safelyCreateQuote();
      });
    },
    methods: {
      async safelyCreateQuote () {
        try {
          await this.createQuote();
        } catch {
          this.removePromoCode();
        }
      },
      createQuote () {
        const promoCode = window.sessionStorage.getItem(PROMO_CODE_STORAGE_KEY) || null;

        return this.$apollo.mutate({
          mutation: CREATE_GOOROO_PASS_QUOTE,
          variables: {
            promoCode
          }
        }).then(({ data: { result: { quote } } }) => {
          this.quote = quote;
        });
      },
      removePromoCode () {
        window.sessionStorage.removeItem(PROMO_CODE_STORAGE_KEY);
        this.createQuote();
      },
      onPromoCodeUpdate (quote) {
        this.quote = quote;
      },
      onComplete () {
        this.showConfirmationDialog = true;
      }
    }
  };
</script>

<style lang="scss">

</style>
