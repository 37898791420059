<template>
  <CheckoutPromoCodeInput
    :expanded="expanded"
    :error="error"
    :loading="loading"
    @expand="expand"
    @input="applyCode"
  />
</template>

<script>
  import { extractUserFacingError } from "@/lib/error-handling";
  import { PROMO_CODE_STORAGE_KEY } from "@/components/GoorooPass/constants";
  import { CREATE_GOOROO_PASS_QUOTE } from "@/components/GoorooPass/queries";
  import CheckoutPromoCodeInput from "./CheckoutPromoCodeInput";

  export default {
    name: "GoorooPassCheckoutPromoCodeInput",
    components: { CheckoutPromoCodeInput },
    data () {
      return {
        error: null,
        expanded: false,
        loading: false
      };
    },
    methods: {
      expand () {
        this.expanded = true;
      },
      applyCode (promoCode) {
        // make sure to clear the previous error, if any
        this.error = null;

        this.loading = true;

        this.$apollo.mutate({
          mutation: CREATE_GOOROO_PASS_QUOTE,
          variables: {
            promoCode
          }
        }).then(({ data: { result: { quote } } }) => {
          this.expanded = false;
          window.sessionStorage.setItem(PROMO_CODE_STORAGE_KEY, promoCode);
          this.$emit("applied", quote);
        }).catch((error) => {
          this.error = extractUserFacingError(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  };
</script>

<style lang="scss">

</style>
