<template>
  <fieldset>
    <div class="goo-input-group is-inline">
      <div class="goo-control is-expanded">
        <label for="name" class="goo-label">Name on card</label>
        <input id="name" v-model="name" autofocus required autocomplete="off" type="text" name="name" class="goo-input">
      </div>
    </div>

    <div class="goo-input-group is-inline">
      <div class="goo-control is-expanded">
        <label for="card-number-element" class="goo-label">Card Number</label>
        <div id="card-number-element" class="goo-input is-stripe-element" />
      </div>
    </div>

    <div class="goo-update-payment-method-dialog-footnote">
      <goo-icon name="lock" />
      <span>Your billing information will be encrypted and kept secure.</span>
    </div>
  </fieldset>
</template>

<script>
  export default {
    name: "CheckoutPaymentMethodEntryForm",
    components: {},
    data () {
      return {
        error: null,
        name: "",
        completions: {
          card: false
        }
      };
    },
    watch: {
      name () {
        this.performValidation();
      }
    },
    mounted () {
      // eslint-disable-next-line
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      this.elements = this.stripe.elements();

      const style = {
        base: {
          color: "#3C4040",
          fontFamily: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "#aab7c4"
          }
        }
      };

      const mapping = [
        { type: "card", target: "#card-number-element" }
      ];

      mapping.forEach((config) => {
        const element = this.elements.create(config.type, { style, ...config.params });
        element.mount(config.target);
        element.on("change", (e) => this.onElementChange(e));
      });
    },
    methods: {
      onElementChange (event) {
        // noinspection JSCheckFunctionSignatures
        this.$set(this.completions, event.elementType, event.complete);
        this.performValidation();
      },
      performValidation () {
        const valid = this.completions.card && this.name.trim() !== "";
        this.$emit("changed", valid);
      },
      generateToken () {
        return new Promise((resolve, reject) => {
          // noinspection JSUnresolvedFunction
          this.stripe.createToken(this.elements.getElement("card"), {
            name: this.name
          }).then((result) => {
            resolve(result.token);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    }
  };
</script>

<style lang="scss">

</style>
