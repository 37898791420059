
/**
 * @param error {Error}
 * @return {string}
 */
export function extractUserFacingError (error) {
  const graphQLErrors = error.graphQLErrors || [];

  const userInputError = graphQLErrors.find((error) => {
    return (
      error.extensions !== undefined &&
      error.extensions.code === "BAD_USER_INPUT"
    );
  });

  if (!userInputError) {
    return "An error has occurred, please try again.";
  }

  return userInputError.message;
}
