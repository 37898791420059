<template>
  <div class="payment-form">
    <h3 class="heading-4">
      Payment Method
    </h3>

    <GooUpdatePaymentMethod
      v-if="updatingCard"
      has-payment-method
      @cancel="updatingCard = false"
      @updated="onCardUpdated"
    />

    <GooBodyTextSkeleton v-if="loading" :lines="8" />
    <form v-else @submit.prevent="onSubmit">
      <template v-if="paymentMethod">
        <CheckoutPaymentMethod :payment-method="paymentMethod" />
        <p>
          <button class="goo-link is-alternate" @click.prevent="updatingCard = true">
            Update Card
          </button>
        </p>
      </template>
      <CheckoutPaymentMethodEntryForm
        v-else
        ref="stripeForm"
        @changed="onEntryFormChanged"
      />

      <hr class="goo-divider">

      <div>
        <p class="body-3">
          By clicking “{{ cta }}”, I understand that my subscription will automatically renew at the monthly subscription
          rate. I can check the renew date and cancel anytime via <router-link :to="{name: 'settings.gooroo-pass'}" target="_blank" class="goo-link is-interactive">
            Gooroo Pass
          </router-link>.

          I also agree to the <a href="https://www.gooroo.com/terms-of-use" target="_blank" class="goo-link is-interactive">Terms of use</a> and
          <a href="https://www.gooroo.com/privacy-policy" target="_blank" class="goo-link is-interactive">Privacy Policy</a>.
        </p>

        <div class="goo-button-group">
          <goo-submit
            class="goo-button is-secondary is-large is-full-width"
            :loading="saving"
            :disabled="!enableSubmitButton"
            loading-text="Processing..."
          >
            {{ cta }}
          </goo-submit>
        </div>
      </div>

      <div class="has-text-centered mt-6 mb-0">
        <img src="@/assets/powered-by-stripe.svg" alt="Powered by Stripe">
      </div>
    </form>
  </div>
</template>

<script>
  import CheckoutPaymentMethod from "./CheckoutPaymentMethod";
  import CheckoutPaymentMethodEntryForm from "./CheckoutPaymentMethodEntryForm";
  import GooUpdatePaymentMethod from "@/goo2/applets/goo-update-payment-method/GooUpdatePaymentMethod";

  import { GET_PAYMENT_METHOD } from "@/components/queries";
  import { SUBSCRIBE_TO_GOOROO_PASS, UPDATE_PAYMENT_METHOD } from "@/components/GoorooPass/mutations";

  export default {
    name: "GoorooPassPaymentForm",
    components: {
      CheckoutPaymentMethod,
      GooUpdatePaymentMethod,
      CheckoutPaymentMethodEntryForm
    },
    props: {
      token: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        error: null,
        saving: false,
        updatingCard: false,
        paymentMethod: null,
        validEntryForm: false
      };
    },
    apollo: {
      paymentMethod: GET_PAYMENT_METHOD
    },
    computed: {
      cta () {
        return "Start free trial";
      },
      loading () {
        return this.token == null || this.$apollo.queries.paymentMethod.loading;
      },
      enableSubmitButton () {
        return this.token != null || this.paymentMethod != null || this.validEntryForm;
      }
    },
    methods: {
      onCardUpdated () {
        this.$apollo.queries.paymentMethod.refresh();
        this.updatingCard = false;
      },
      async onSubmit () {
        this.saving = true;

        try {
          const paymentMethodID = await this.getPaymentMethodID();

          await this.$apollo.mutate({
            mutation: SUBSCRIBE_TO_GOOROO_PASS,
            variables: {
              token: this.token,
              paymentMethod: paymentMethodID
            }
          });

          this.$emit("complete");
        } catch (e) {
          this.$goo.dialog.alert({
            title: "There was a problem completing your purchase",
            type: "is-error",
            hasIcon: true
          });
        } finally {
          this.saving = false;
        }
      },
      async getPaymentMethodID () {
        if (this.paymentMethod !== null) {
          return this.paymentMethod.id;
        }

        const token = await this.$refs.stripeForm.generateToken();

        const { data: { result } } = await this.$apollo.mutate({
          mutation: UPDATE_PAYMENT_METHOD,
          variables: {
            input: {
              stripeToken: token.id
            }
          }
        });

        if (!result.ok) {
          throw Error(result.error);
        }

        return result.paymentMethod.id;
      },
      onEntryFormChanged (valid) {
        this.validEntryForm = valid;
      }
    }
  };
</script>

<style lang="scss">
</style>
